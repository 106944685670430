import React, { Component } from 'react'
import { Modal, Form, Input, Icon, Spin } from 'antd'

const spin = <Icon type="loading" style={{ fontSize: 36 }} spin />;

const AddMemberForm = Form.create({ name: 'add_member' })(

    class extends Component {

        render() {
            const { visible, onCancel, onCreate, form, loading } = this.props;
            const { getFieldDecorator } = form;
            return (

                <Modal
                    title="เพิ่มผู้ใช้"
                    okText={'บันทึก'}
                    cancelText={'ยกเลิก'}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    <Spin indicator={spin} spinning={loading} >
                        <Form layout="vertical">
                            <Form.Item label="อีเมลล์">
                                {getFieldDecorator('member_email', {
                                    initialValue: "",
                                    rules: [
                                        { required: true, message: 'กรุณาใส่อีเมลล์' },
                                        // eslint-disable-next-line no-useless-escape
                                        { pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'กรุณาใส่อีเมลล์ให้ถูกต้อง' }
                                    ],
                                })(<Input placeholder="กรุณาใส่อีเมลล์" />)}
                            </Form.Item>
                            <Form.Item label="ชื่อ">
                                {getFieldDecorator('member_firstname', {
                                    initialValue: "",
                                    rules: [
                                        { required: true, message: 'กรุณาใส่ชื่อ' },
                                    ],
                                })(<Input placeholder="ชื่อ" />)}
                            </Form.Item>
                            <Form.Item label="นามสกุล">
                                {getFieldDecorator('member_lastname', {
                                    initialValue: "",
                                    rules: [
                                        { required: true, message: 'กรุณาใส่นามสกุล' }
                                    ],
                                })(<Input placeholder="กรุณาใส่นามสกุล" />)}
                            </Form.Item>
                            <Form.Item label="รหัสผ่าน">
                                {getFieldDecorator('member_pass', {
                                    initialValue: "",
                                    rules: [
                                        { required: true, message: 'กรุณาใส่รหัสผ่าน' }
                                    ]
                                })(<Input.Password placeholder="กรุณาใส่รหัสผ่าน" />)}
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            );
        }
    },
);

export { AddMemberForm };
