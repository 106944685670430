import React, { Component } from 'react'
import { Modal, Form, Input, Button, notification, Select, DatePicker } from 'antd'
import 'antd/dist/antd.css';
import '../place.css';
import FileUpload from '../../../components/FileUpload';
import CKEditor from "@ckeditor/ckeditor5-react";// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import editorConfiguration from '../../../variables/editorConfiguration';
import domain from '../../../service/config'
import provinces from './province';
import moment from 'moment';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class EditPlaceForm extends Component {

    constructor(props) {
        super(props);

        const { place } = props;

        this.state = {
            show_image: {
                file: domain + place.place_image,
                name: ""
            },
            place_image: null,
            place_description: place.place_description,
            place_title: place.place_title,
            place_author: place.place_author,
            place_type: place.place_type,
            place_province: place.place_province,
            place_date: place.place_date,
            loading: false,
        }
    }

    inputImageOnChange = (e) => {

        const file = e.target.files[0]
        if (file != null) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let image = this.state.image
                image = { file: reader.result, name: file.name };

                // this.props.onSetImageFile(image);
                this.setState({ place_image: file, show_image: image })
            }
            reader.onerror = error => {
                console.error(error)
            }
        } else {
            console.log("no file");

        }

    }

    onEditPlace = async (e) => {
        e.preventDefault();

        let place = {
            _id: this.props.place._id,
            place_title: "",
            place_image: "",
            place_description: "",
            place_author: "",
            place_type: "",
            place_province: "",
            place_date: "",
        };

        const { place_title, place_author, place_image, place_description, place_type,place_province, place_date } = this.state;
        const old_place = this.props.place;


        place.place_title = place_title !== "" ? place_title : old_place.place_title;
        place.place_image = (place_image !== "" && place_image != null) ? place_image : "";
        place.place_author = place_author !== "" ? place_author : old_place.place_title;
        place.place_description = place_description !== "" ? place_description : old_place.place_description;
        place.place_type = place_type !== "" ? place_type : old_place.place_type;
        place.place_province = place_province !== "" ? place_province : old_place.place_province;
        place.place_date = place_date !== "" ? moment(place_date) : moment(old_place.place_date);

        const response = await this.props.onEditPlace(place);

        if (response.success) {
            this.onCancelEditPlace();
        } else {
            notify(response.success, response.message);
        }
    }

    onCancelEditPlace = () => {
        this.setState({
            place_image: null,
            show_image: null,
            place_description: "",
            place_title: "",
            place_author: "",
            place_type: "",
            place_province: "",
            place_date: "",
        });

        this.props.onCancel();
    }

    render() {
        const { visible } = this.props;

        const { place_title, place_author, place_description, show_image, place_date } = this.state;

        let disabled = true;
        if (place_title !== "" && place_author !== "" && place_description !== "" && place_date !== "") {
            disabled = false;
        }

        return (
            <Modal
                maskClosable={false}
                style={{ top: 15 }}
                width={800}
                title="แก้ไขสถานที่/ร้านค้า/ที่พัก"
                visible={visible}
                onCancel={this.onCancelEditPlace}
                footer={[
                    <Button key="back" onClick={this.onCancelEditPlace}>
                        ยกเลิก
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.onEditPlace} loading={this.props.loading} disabled={disabled}>
                        บันทึก
                        </Button>,
                ]}
            >
                <Form layout="vertical">
                    <Form.Item label="ประเภทสถานที่">
                        <Select defaultValue={this.state.place_type} onChange={(e) => { this.setState({ place_type: e }) }}>
                            <Select.Option value="ร้านค้า">ร้านค้า</Select.Option>
                            <Select.Option value="ที่พัก">ที่พัก</Select.Option>
                            <Select.Option value="สถานที่อื่นๆ">สถานที่อื่นๆ</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="จังหวัด">
                        <Select defaultValue={this.state.place_province} onChange={(e) => { this.setState({ place_province: e }) }}>
                            { provinces.map((province) => <Select.Option key={province} value={province}>{province}</Select.Option>) }
                        </Select>
                    </Form.Item>
                    <Form.Item label="อัพโหลดรูปภาพ (ขนาดภาพที่แนะนำคือ 800 X 400)">
                        <FileUpload id="place_image" inputImageOnChange={this.inputImageOnChange} image={show_image} />
                    </Form.Item>
                    <Form.Item label="หัวเรื่อง">
                        <Input placeholder="กรุณาใส่หัวเรื่อง" value={place_title} onChange={(e) => { this.setState({ place_title: e.target.value }) }} />
                    </Form.Item>
                    <Form.Item label="วันที่เขียน">
                        <DatePicker style={{ width: "100%" }} defaultValue={moment(place_date)} onChange={(date, dateString) => { this.setState({ place_date: dateString }) }} placeholder="กรุณาเลือกวันที่" />
                    </Form.Item>
                    <Form.Item label="บทความ">
                        <CKEditor
                            style={{ borderColor: "red" }}
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={place_description}
                            onChange={(event, editor) => {
                                const data = editor.getData();

                                // this.props.onSetDescription(data);
                                this.setState({ place_description: data })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="ผู้เขียน">
                        <Input placeholder="กรุณาใส่ชื่อผู้เขียน" value={place_author} onChange={(e) => { this.setState({ place_author: e.target.value }) }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export { EditPlaceForm };
