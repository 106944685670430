import { Dashboard } from '../containers/dashboard'
import { Blog } from '../containers/blog'
import { News } from '../containers/news'
import { Place } from '../containers/place'
import { Support } from '../containers/support'
import { Gallery } from '../containers/gallery'
import { Setting } from '../containers/setting'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/blog',
    exact: false,
    name: 'blog',
    component: Blog,
  },
  {
    path: '/news',
    exact: false,
    name: 'news',
    component: News,
  },
  {
    path: '/place',
    exact: false,
    name: 'place',
    component: Place,
  },
  {
    path: '/support',
    exact: false,
    name: 'support',
    component: Support,
  },
  {
    path: '/gallery',
    exact: false,
    name: 'gallery',
    component: Gallery,
  },
  {
    path: '/setting',
    exact: false,
    name: 'setting',
    component: Setting,
  }
]


export default routes;
