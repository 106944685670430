import React, { Component } from 'react'
import { Modal, Form, Input, Button, notification, DatePicker } from 'antd'
import 'antd/dist/antd.css';
import '../news.css';
import FileUpload from '../../../components/FileUpload';
import CKEditor from "@ckeditor/ckeditor5-react";// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import editorConfiguration from '../../../variables/editorConfiguration';
import moment from 'moment';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class AddNewsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_image: null,
            news_image: null,
            news_description: "",
            news_title: "",
            news_author: "",
            news_date: "",
            loading: false
        }
    }

    inputImageOnChange = (e) => {

        const file = e.target.files[0]
        if (file != null) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let image = this.state.image
                image = { file: reader.result, name: file.name };

                // this.props.onSetImageFile(image);
                this.setState({ news_image: file, show_image: image })
            }
            reader.onerror = error => {
                console.error(error)
            }
        }

    }

    onCreateNews = async (e) => {
        e.preventDefault();
        const { news_title, news_author, news_image, news_description, news_date } = this.state;
        const news = { news_title, news_author, news_image, news_description, news_date: moment(news_date) };
        const response = await this.props.onCreateNews(news);

        if (response.success) {
            this.onCancelNews();
        } else {
            notify(response.success, response.message);
        }
    }

    onCancelNews = () => {
        this.setState({
            news_image: null,
            show_image: null,
            news_description: "",
            news_title: "",
            news_author: "",
            news_date: ""
        });

        this.props.onCancel();
    }

    render() {
        const { visible } = this.props;

        const { news_title, news_author, news_image, news_description, news_date } = this.state;

        let disabled = false;
        if (news_title === "" || news_author === "" || news_image == null || news_description === "" || news_date === "") {
            disabled = true;
        }

        return (
            <Modal
                maskClosable={false}
                style={{ top: 15 }}
                width={800}
                title="เพิ่มข่าวสาร"
                visible={visible}
                onCancel={this.onCancelNews}
                footer={[
                    <Button key="back" onClick={this.onCancelNews}>
                        ยกเลิก
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.onCreateNews} loading={this.props.loading} disabled={disabled}>
                        บันทึก
                        </Button>,
                ]}
            >
                <Form layout="vertical">
                    <Form.Item label="อัพโหลดรูปภาพ (ขนาดภาพที่แนะนำคือ 800 X 400)">
                        <FileUpload id="news_image" inputImageOnChange={this.inputImageOnChange} image={this.state.show_image} />
                    </Form.Item>
                    <Form.Item label="หัวเรื่อง">
                        <Input placeholder="กรุณาใส่หัวเรื่อง" value={this.state.news_title} onChange={(e) => { this.setState({ news_title: e.target.value }) }} />
                    </Form.Item>
                    <Form.Item label="วันที่เขียน">
                        <DatePicker style={{ width: "100%" }} onChange={(date, dateString) => { this.setState({ news_date: dateString }) }} placeholder="กรุณาเลือกวันที่" />
                    </Form.Item>
                    <Form.Item label="บทความ">
                        <CKEditor
                            style={{ borderColor: "red" }}
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={this.state.news_description}
                            onChange={(event, editor) => {
                                const data = editor.getData();

                                // this.props.onSetDescription(data);
                                this.setState({ news_description: data })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="ผู้เขียน">
                        <Input placeholder="กรุณาใส่ชื่อผู้เขียน" value={this.state.news_author} onChange={(e) => { this.setState({ news_author: e.target.value }) }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export { AddNewsForm };
