import React, { Component } from 'react';
import { Button, Divider, Col, Row, Card, Icon, Empty, Pagination, Typography, Input, Spin, Table, notification } from 'antd'
import './dashboard.css'
import axios from '../../service/service'
import moment from 'moment';
import 'moment/locale/th';
import Highlighter from 'react-highlight-words';
import { AddMemberForm } from './form/AddMemberForm';
import { DeleteMemberForm } from './form/DeleteMemberForm';
import { EditMemberForm } from './form/EditMemberForm';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class Dashboard extends Component {
    constructor(props) {
        super(props);

        moment().locale('th');

        this.state = {
            loading: false,
            members: null,
            searchText: '',
            searchedColumn: '',
            loadingAdd: false,
            loadingEdit: false,
            loadingDelete: false,
            visibleAddForm: false,
            visibleEditForm: false,
            visibleDeleteForm: false,
            currentEditMember: null,
            currentDeleteMember: null,
            error: null,
        };
    }

    componentDidMount() {
        document.title = 'หน้าหลัก';
        this.getMember();
    }

    getColumnSearchProps = (searchText, dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา ${searchText}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    ค้นหา
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    รีเซ็ต
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getMember = async () => {
        this._isMounted = true;
        try {
            const response = await axios.get('/member', {
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            });

            if (this._isMounted) {
                this.setState({
                    members: response.data.length === 0 ? null : response.data,
                });
            }

        } catch (error) {

            if (this._isMounted) {
                this.setState({
                    members: error
                });
            }
        }
    }

    getColumns() {
        const columns = [
            {
                title: 'ลำดับ',
                key: 'index',
                dataIndex: '_id',
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: 'อีเมลล์',
                dataIndex: 'member_email',
                key: 'member_email',
                align: 'center',
                ...this.getColumnSearchProps('อีเมลล์', 'member_email'),
            },
            {
                title: 'ชื่อ',
                dataIndex: 'member_firstname',
                key: 'member_firstname',
                align: 'center',
                ...this.getColumnSearchProps('ชื่อ', 'member_firstname'),
            },
            {
                title: 'นามสกุล',
                dataIndex: 'member_lastname',
                key: 'member_lastname',
                align: 'center',
                ...this.getColumnSearchProps('นามสกุล', 'member_lastname'),
            },
            {
                title: 'วันที่สร้าง',
                dataIndex: 'createdAt',
                align: 'center',
                key: 'createdAt',
                render: (text, record, index) => {
                    return moment(text).format("DD-MM-YYYY");
                }
            },
            {
                title: 'แก้ไข/ลบ',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <span key={record._id}>
                        <Button key="edit" value={record._id} type="primary" icon="edit" onClick={() => this.showEditModal(record)} />
                        <Divider type="vertical" />
                        <Button key="close" value={record._id} type="danger" icon="close" onClick={() => this.showDeleteModal(record)} />
                    </span>
                ),
            },
        ];

        return columns;
    }

    showAddModal = () => {
        this.setState({ visibleAddForm: true });
    }

    handleCancelAddForm = () => {
        const { form } = this.AddFormRef.props;
        form.resetFields();

        this.setState({ visibleAddForm: false });
    };

    handleAddForm = () => {
        const { form } = this.AddFormRef.props;

        form.validateFields(async (err, values) => {

            this.setState({
                loadingAdd: true
            });

            if (!err) {

                try {
                    values["member_role"] = 2;

                    const response = await axios.post('/member', values, {
                        headers: {
                            Authorization: localStorage.getItem('token')
                        }
                    });

                    this.handleCancelAddForm();
                    this.getMember();

                    this.setState({
                        visibleAddForm: false,
                        loadingAdd: false
                    });

                } catch (error) {
                    notify(false, error.message + ": ไม่สามารถเพิ่มผู้ใช้ได้เนื่องจากอีเมลล์ถูกใช้งานแล้ว");

                    this.setState({
                        error: error.response.data.error,
                        visibleAddForm: false,
                        loadingAdd: false
                    });
                }
            } else {
                this.setState({
                    loadingAdd: false
                });
            }

        });

    };

    saveAddFormRef = formRef => {
        this.AddFormRef = formRef;
    };

    showDeleteModal = (record) => {
        this.setState({ visibleDeleteForm: true, currentDeleteMember: record });
    }

    handleCancelDeleteForm = () => {
        const { form } = this.DeleteFormRef.props;
        form.resetFields();

        this.setState({ visibleAddForm: false, currentDeleteMember: null });
    };

    handleDeleteForm = async () => {
        this.setState({
            loadingDelete: true
        });

        try {
            const _id = this.state.currentDeleteMember._id;
            const response = await axios.delete('/member/' + _id, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });

            this.getMember();
            this.handleCancelDeleteForm();

            notify(true, "ลบข้อมูลผู้ใช้เรียบร้อย");

            this.setState({
                visibleDeleteForm: false,
                loadingDelete: false
            });


        } catch (error) {

            notify(false, error.message);

            this.setState({
                error: error.message,
                visibleDeleteForm: false,
                loadingDelete: false
            });
        }
    };

    showEditModal = (record) => {
        this.setState({ visibleEditForm: true, currentEditMember: record });
    }

    handleCancelEditMemberForm = () => {
        const { form } = this.editMemberFormRef.props;
        form.resetFields();

        this.setState({ visibleEditForm: false });
    };

    handleEditMemberForm = () => {
        const { form } = this.editMemberFormRef.props;

        form.validateFields(async (err, values) => {

            this.setState({
                loadingEdit: true
            });

            if (!err) {

                if (values.member_pass === "") {
                    delete values.member_pass;
                }
                
                try {
                    const _id = this.state.currentEditMember._id;
                    const response = await axios.patch('/member/update/' + _id, values, {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                            "Content-Type": "application/json"
                        }
                    });

                    this.getMember();
                    this.handleCancelEditMemberForm();

                    notify(true, "แก้ไขข้อมูลผู้ใช้เรียบร้อย");

                    this.setState({
                        profile: response.data,
                        visibleEditForm: false,
                        loadingEdit: false
                    });

                } catch (error) {

                    notify(false, error.message);
                    this.setState({
                        profile: error.message,
                        visibleEditForm: false,
                        loadingEdit: false
                    });
                }
            }

        });

    };

    saveEditMemberFormRef = formRef => {
        this.editMemberFormRef = formRef;
    };

    render() {

        const columns = this.getColumns();

        return (
            <div>
                <Typography.Title className="title-default title-top" level={3}>
                    จัดการผู้ใช้
                </Typography.Title>
                <Divider />

                <div style={{ marginBottom: 8 }}>
                    <Button
                        type="primary"
                        icon="plus"
                        className="block"
                        onClick={this.showAddModal}
                    >
                        เพิ่ม
                        </Button>
                </div>

                <Table columns={columns} dataSource={this.state.members} rowKey="_id" />

                <AddMemberForm
                    wrappedComponentRef={this.saveAddFormRef}
                    visible={this.state.visibleAddForm}
                    loading={this.state.loadingAdd}
                    onCancel={this.handleCancelAddForm}
                    onCreate={this.handleAddForm}
                />

                {this.state.currentDeleteMember != null ?
                    <DeleteMemberForm
                        visible={this.state.visibleDeleteForm}
                        data={this.state.currentDeleteMember}
                        loading={this.state.loadingDelete}
                        onCancel={this.handleCancelDeleteForm}
                        onCreate={this.handleDeleteForm}
                    /> : null}

                {this.state.currentEditMember != null ?

                    <EditMemberForm
                        wrappedComponentRef={this.saveEditMemberFormRef}
                        visible={this.state.visibleEditForm}
                        data={this.state.currentEditMember}
                        loading={this.state.loadingEdit}
                        onCancel={this.handleCancelEditMemberForm}
                        onCreate={this.handleEditMemberForm}

                    /> : null}
            </div>
        );
    }
}

export default Dashboard;