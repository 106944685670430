import React, { Component } from 'react'
import { Modal, Form, Input, Icon, Spin } from 'antd'

const spin = <Icon type="loading" style={{ fontSize: 36 }} spin />;

const EditProfileForm = Form.create({ name: 'edit_profile' })(

    class extends Component {

        storeValidate = async (rule, value, callback) => {
            if (!value) {
                callback("กรุณาใส่ชื่อร้าน")
            }
        }

        render() {
            const { visible, onCancel, onCreate, form, data, loading } = this.props;
            const { getFieldDecorator } = form;
            return (

                <Modal
                    title="โปรไฟล์"
                    okText={'บันทึก'}
                    cancelText={'ยกเลิก'}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    <Spin indicator={spin} spinning={loading} >
                        {data != null ?
                            <Form layout="vertical">
                                <Form.Item label="อีเมลล์">
                                    {getFieldDecorator('member_email', {
                                        initialValue: data.member_email,
                                        rules: [
                                            { required: true, message: 'กรุณาใส่อีเมลล์' },
                                            // eslint-disable-next-line no-useless-escape
                                            { pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'กรุณาใส่อีเมลล์ให้ถูกต้อง' }
                                        ],
                                    })(<Input placeholder="กรุณาใส่อีเมลล์" />)}
                                </Form.Item>
                                <Form.Item label="ชื่อ">
                                    {getFieldDecorator('member_firstname', {
                                        initialValue: data.member_firstname,
                                        rules: [
                                            { required: true, message: 'กรุณาใส่ชื่อ' },
                                        ],
                                    })(<Input placeholder="ชื่อ" />)}
                                </Form.Item>
                                <Form.Item label="นามสกุล">
                                    {getFieldDecorator('member_lastname', {
                                        initialValue: data.member_lastname,
                                        rules: [
                                            { required: true, message: 'กรุณาใส่นามสกุล' }
                                        ],
                                    })(<Input placeholder="กรุณาใส่นามสกุล" />)}
                                </Form.Item>
                                <Form.Item label="รหัสผ่าน">
                                    {getFieldDecorator('member_pass', {
                                        initialValue: "",
                                    })(<Input.Password placeholder="กรุณาใส่รหัสผ่าน หากต้องการแก้ไข" />)}
                                </Form.Item>
                            </Form>
                            : null}
                    </Spin>
                </Modal>
            );
        }
    },
);

export { EditProfileForm };
