import React, { Component } from 'react';
import { Button, Divider, Col, Row, Card, Icon, Empty, Pagination, Typography, Input, Spin } from 'antd'
import './place.css'
import axios from '../../service/service'
import moment from 'moment';
import 'moment/locale/th';
import { AddPlaceForm } from './form/AddPlaceForm';
import { EditPlaceForm } from './form/EditPlaceForm';
import { DeletePlaceForm } from './form/DeletePlaceForm';
import domain from '../../service/config'

class Place extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        moment().locale('th');

        this.state = {
            place: null,
            total: null,
            per_page: null,
            total_pages: null,
            current_page: 1,
            row: 4,
            loadingAdd: false,
            loadingEdit: false,
            loadingDelete: false,
            visibleAddForm: false,
            visibleEditForm: false,
            visibleDeleteForm: false,
            currentEditPlace: null,
            currentDeletePlace: null,
            status: 1,
            loading: false,
        };
    }

    componentDidMount() {
        document.title = 'ข่าวสถานที่/ร้านค้า/ที่พัก';
        this.getPlaces(1);
    }

    getPlaces = async (pageNumber) => {
        this._isMounted = true;
        try {
            const response = await axios.get('/place?page=' + pageNumber);

            if (this._isMounted) {
                this.setState({
                    place: response.data.place.length === 0 ? null : response.data.place,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    place: error.message
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderPlaces = () => {
        if (this.state.place != null) {
            let rows = [];

            for (let i = 0; i < Math.ceil(this.state.place.length / this.state.row); i++) {
                rows.push(i);
            }

            let start = 0
            let end = this.state.row;
            let place = rows.map(i => {
                let place = this.state.place.slice(i + start, i + end);

                start = end - 1;
                end = start + this.state.row;

                const new_ = place.map((key, index) => {
                    const { Meta } = Card;

                    return (<Col key={key._id} md={6} xm={6} style={{ paddingBottom: 8 }}>
                        <Card
                            title={key.place_type}
                            style={{ width: '100%' }}
                            cover={<img alt={key._id} src={domain + key.place_image} height="200" />}
                            actions={[
                                <Icon type="close" key="close" onClick={() => this.showDeleteModal(key)} value={key._id} />,
                                <Icon type="edit" key="edit" onClick={() => this.showEditModal(key)} value={key._id} />
                            ]}
                        >
                            <Meta title={key.place_title} description={'วันที่ ' + moment(key.place_date).format('DD/MM/YYYY')} />
                        </Card>
                    </Col>);
                })

                let row = <Row gutter={6} justify="space-around" align="middle" key={i}>{new_}</Row>

                return row;
            });

            return place;
        }

        return <Empty description={'ไม่มีสถานที่/ร้านค้า/ที่พัก'} imageStyle={{
            height: 120,
        }}>
            <Button type="primary" icon="plus" onClick={this.showAddModal}>เพิ่มสถานที่/ร้านค้า/ที่พัก</Button>
        </Empty>;
    }

    renderPagination = () => {
        return this.state.place != null ?
            <Row justify="center" align="middle" type="flex">
                <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
            </Row> : null;
    }

    onChangePage = page => {

        if (this.state.status == 1) {
            this.getPlaces(page);
        } else if (this.state.status == 2) {
            this.getSearch(page);
        }

        this.setState({
            current_page: page,
        });
    };

    getSearch = async (pageNumber) => {
        try {
            const response = await axios.get('/search/bof/place?word=' + this.state.search + "&page=" + pageNumber);

            if (this._isMounted) {
                this.setState({
                    place: response.data.place.length === 0 ? null : response.data.place,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    place: error.response.data.error
                });
            }
        }
    }

    search = async (value) => {
        this.setState({
            loading: true,
            search: value === "" ? "" : value
        });

        try {
            const response = await axios.get('/search/bof/place?word=' + value + "&page=1");

            if (this._isMounted) {
                this.setState({
                    place: response.data.place.length === 0 ? null : response.data.place,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                    status: 2
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    place: error.response.data.error
                });
            }
        }

        this.setState({
            loading: false
        });
    }

    showAddModal = () => {
        this.setState({ visibleAddForm: true });
    };

    handleCancelAddForm = () => {
        this.setState({ visibleAddForm: false });
    };

    handleAddForm = async (place) => {

        let data = new FormData();

        for (const [key, value] of Object.entries(place)) {
            data.append(key, value);
        }

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.post('/place', data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
            // console.log(response);
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getPlaces(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    };

    showEditModal = (place) => {
        this.setState({ visibleEditForm: true, currentEditPlace: place });
    };

    handleCancelEditForm = () => {
        this.setState({ visibleEditForm: false, currentEditPlace: null });
    };

    handleEditForm = async (place) => {
        let data = new FormData();

        const _id = place._id;
        delete place._id;

        for (const [key, value] of Object.entries(place)) {
            data.append(key, value);
        }

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.patch('/place/' + _id, data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getPlaces(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    }

    showDeleteModal = (place) => {
        this.setState({ visibleDeleteForm: true, currentDeletePlace: place });
    };

    handleCancelDeleteForm = () => {
        this.setState({ visibleDeleteForm: false, currentDeletePlace: null });
    };

    handleDeleteForm = async (place) => {
        const _id = place._id;

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.delete('/place/' + _id, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getPlaces(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    }

    render() {
        const place = this.renderPlaces();
        const pagination = this.renderPagination();

        return (
            <div>
                <Typography.Title className="title-default title-top" level={3}>
                    สถานที่/ร้านค้า/ที่พัก
                    <Input.Search
                        placeholder="ค้นหาสถานที่/ร้านค้า/ที่พัก"
                        enterButton="ค้นหา"
                        style={{ maxWidth: 300, float: 'right' }}
                        onSearch={this.search}
                    />
                </Typography.Title>
                <Divider />
                {this.state.place != null ?
                    <div style={{ marginBottom: 8 }}>
                        <Button
                            type="primary"
                            icon="plus"
                            className="block"
                            onClick={this.showAddModal}
                        >
                            เพิ่ม
                        </Button>
                        <div style={{ float: 'right' }} className="pagination-top">
                            <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
                        </div>
                    </div> : null}
                <Spin tip="Loading..." spinning={this.state.loading}>
                    {place}
                </Spin>


                {pagination}

                <AddPlaceForm
                    visible={this.state.visibleAddForm}
                    onCancel={this.handleCancelAddForm}
                    onCreatePlace={this.handleAddForm}
                    loading={this.state.loadingAdd}
                />

                {this.state.currentEditPlace != null ?
                    <EditPlaceForm
                        visible={this.state.visibleEditForm}
                        onCancel={this.handleCancelEditForm}
                        onEditPlace={this.handleEditForm}
                        loading={this.state.loadingEdit}
                        place={this.state.currentEditPlace}
                    /> : null}

                {this.state.currentDeletePlace != null ?
                    <DeletePlaceForm
                        visible={this.state.visibleDeleteForm}
                        onCancel={this.handleCancelDeleteForm}
                        onDeletePlace={this.handleDeleteForm}
                        loading={this.state.loadingDelete}
                        place={this.state.currentDeletePlace}
                    /> : null}

            </div>
        );
    }
}

export default Place;