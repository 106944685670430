import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './FileUpload.css'
import { Button } from 'antd';

class FileUpload extends Component {

    onClickFileUpload = () => {
        window.document.getElementById("my-upload").click()
    }

    render() {

        return (
            <div>
                {this.props.image != null ?
                    <a onClick={this.onClickFileUpload} >
                        <div className="my-picture">
                            <img src={this.props.image.file} className="my-img my-img-mobile" alt={this.props.image.file.name} />
                        </div></a> : null}


                <span tabIndex="0" className="ant-upload" role="button" >
                    <input type="file" onChange={this.props.inputImageOnChange} accept="image/*" style={{ display: "none" }} id="my-upload" />
                    <Button block icon="upload" onClick={this.onClickFileUpload}> คลิกที่นี่เพื่ออัพโหลด</Button>
                </span>

            </div>
        );
    }
}

export default FileUpload;