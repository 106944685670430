import React, { Component } from 'react';
import { Button, Divider, Col, Row, Card, Icon, Empty, Pagination, Typography, Input, Spin } from 'antd'
import './blog.css'
import axios from '../../service/service'
import moment from 'moment';
import 'moment/locale/th';
import { AddBlogForm } from './form/AddBlogForm';
import { EditBlogForm } from './form/EditBlogForm';
import { DeleteBlogForm } from './form/DeleteBlogForm';
import domain from '../../service/config'

class Blog extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        moment().locale('th');

        this.state = {
            blogs: null,
            total: null,
            per_page: null,
            total_pages: null,
            current_page: 1,
            row: 4,
            loadingAdd: false,
            loadingEdit: false,
            loadingDelete: false,
            visibleAddForm: false,
            visibleEditForm: false,
            visibleDeleteForm: false,
            currentEditBlog: null,
            currentDeleteBlog: null,
            status: 1,
            loading: false,
        };
    }

    componentDidMount() {
        document.title = 'บล็อก';
        this.getBlogs(1);
    }

    getBlogs = async (pageNumber) => {
        this._isMounted = true;
        try {
            const response = await axios.get('/blog?page=' + pageNumber);

            if (this._isMounted) {
                this.setState({
                    blogs: response.data.blogs.length === 0 ? null : response.data.blogs,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    blogs: error.message
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderBlogs = () => {
        if (this.state.blogs != null) {
            let rows = [];

            for (let i = 0; i < Math.ceil(this.state.blogs.length / this.state.row); i++) {
                rows.push(i);
            }

            let start = 0
            let end = this.state.row;
            let blogs = rows.map(i => {
                let blogs = this.state.blogs.slice(i + start, i + end);

                start = end - 1;
                end = start + this.state.row;

                const blogs_ = blogs.map((key, index) => {
                    const { Meta } = Card;

                    return (<Col key={key._id} md={6} xm={6} style={{ paddingBottom: 8 }}>
                        <Card
                            style={{ width: '100%' }}
                            cover={<img alt={key._id} src={domain + key.blog_image} height="200" />}
                            actions={[
                                <Icon type="close" key="close" onClick={() => this.showDeleteModal(key)} value={key._id} />,
                                <Icon type="edit" key="edit" onClick={() => this.showEditModal(key)} value={key._id} />
                            ]}
                        >
                            <Meta title={key.blog_title} description={'วันที่ ' + moment(key.blog_date).format('DD/MM/YYYY')} />
                        </Card>
                    </Col>);
                })

                let row = <Row gutter={6} justify="space-around" align="middle" key={i}>{blogs_}</Row>

                return row;
            });

            return blogs;
        }

        return <Empty description={'ไม่มีบล้อก'} imageStyle={{
            height: 120,
        }}>
            <Button type="primary" icon="plus" onClick={this.showAddModal}>เพิ่มบล้อก</Button>
        </Empty>;
    }

    renderPagination = () => {
        return this.state.blogs != null ?
            <Row justify="center" align="middle" type="flex">
                <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
            </Row> : null;
    }

    onChangePage = page => {

        if (this.state.status == 1) {
            this.getBlogs(page);
        } else if (this.state.status == 2) {
            this.getSearch(page);
        }

        this.setState({
            current_page: page,
        });
    };

    getSearch = async (pageNumber) => {
        try {
            const response = await axios.get('/search/blog?word=' + this.state.search + "&page=" + pageNumber);

            if (this._isMounted) {
                this.setState({
                    blogs: response.data.blogs.length === 0 ? null : response.data.blogs,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    blogs: error.response.data.error
                });
            }
        }
    }

    search = async (value) => {
        this.setState({
            loading: true,
            search: value === "" ? "" : value
        });

        try {
            const response = await axios.get('/search/blog?word=' + value + "&page=1");

            if (this._isMounted) {
                this.setState({
                    blogs: response.data.blogs.length === 0 ? null : response.data.blogs,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                    status: 2
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    blogs: error.response.data.error
                });
            }
        }

        this.setState({
            loading: false
        });
    }

    showAddModal = () => {
        this.setState({ visibleAddForm: true });
    };

    handleCancelAddForm = () => {
        this.setState({ visibleAddForm: false });
    };

    handleAddForm = async (blog) => {
        let data = new FormData();

        for (const [key, value] of Object.entries(blog)) {
            data.append(key, value);
        }

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.post('/blog', data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });

            // console.log(response);
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getBlogs(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    };

    showEditModal = (blog) => {
        this.setState({ visibleEditForm: true, currentEditBlog: blog });
    };

    handleCancelEditForm = () => {
        this.setState({ visibleEditForm: false, currentEditBlog: null });
    };

    handleEditForm = async (blog) => {
        let data = new FormData();

        const _id = blog._id;
        delete blog._id;

        for (const [key, value] of Object.entries(blog)) {
            data.append(key, value);
        }

        let res = { success: false, message: "" };
        this.setState({
            loadingEdit: true
        })

        try {
            const response = await axios.patch('/blog/' + _id, data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getBlogs(1);

        this.setState({
            loadingEdit: false,
            status: 1
        });

        return res;
    }

    showDeleteModal = (blog) => {
        this.setState({ visibleDeleteForm: true, currentDeleteBlog: blog });
    };

    handleCancelDeleteForm = () => {
        this.setState({ visibleDeleteForm: false, currentDeleteBlog: null });
    };

    handleDeleteForm = async (blog) => {
        const _id = blog._id;

        let res = { success: false, message: "" };
        this.setState({
            loadingDelete: true
        })

        try {
            const response = await axios.delete('/blog/' + _id, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getBlogs(1);

        this.setState({
            loadingDelete: false,
            status: 1
        });

        return res;
    }

    render() {
        const blogs = this.renderBlogs();
        const pagination = this.renderPagination();

        return (
            <div>
                <Typography.Title className="title-default title-top" level={3}>
                    บล็อก
                    <Input.Search
                        placeholder="ค้นหาบล็อก"
                        enterButton="ค้นหา"
                        style={{ maxWidth: 300, float: 'right' }}
                        onSearch={this.search}
                    />
                </Typography.Title>
                <Divider />
                {this.state.blogs != null ?
                    <div style={{ marginBottom: 8 }}>
                        <Button
                            type="primary"
                            icon="plus"
                            className="block"
                            onClick={this.showAddModal}
                        >
                            เพิ่ม
                        </Button>
                        <div style={{ float: 'right' }} className="pagination-top">
                            <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
                        </div>
                    </div> : null}
                <Spin tip="Loading..." spinning={this.state.loading}>
                    {blogs}
                </Spin>

                {pagination}

                <AddBlogForm
                    visible={this.state.visibleAddForm}
                    onCancel={this.handleCancelAddForm}
                    onCreateBlog={this.handleAddForm}
                    loading={this.state.loadingAdd}
                />

                {this.state.currentEditBlog != null ?
                    <EditBlogForm
                        visible={this.state.visibleEditForm}
                        onCancel={this.handleCancelEditForm}
                        onEditBlog={this.handleEditForm}
                        loading={this.state.loadingEdit}
                        blog={this.state.currentEditBlog}
                    /> : null}

                {this.state.currentDeleteBlog != null ?
                    <DeleteBlogForm
                        visible={this.state.visibleDeleteForm}
                        onCancel={this.handleCancelDeleteForm}
                        onDeleteBlog={this.handleDeleteForm}
                        loading={this.state.loadingDelete}
                        blog={this.state.currentDeleteBlog}
                    /> : null}

            </div>
        );
    }
}

export default Blog;