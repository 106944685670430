import React, { Component } from 'react'
import { Modal, Button, notification, Typography } from 'antd'
import 'antd/dist/antd.css';
import '../place.css';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class DeletePlaceForm extends Component {

    onDeletePlace = async (e) => {
        e.preventDefault();

        const place = this.props.place;
        const response = await this.props.onDeletePlace(place);

        if (response.success) {
            this.props.onCancel();
        } else {
            notify(response.success, response.message);
        }
    }

    render() {
        const { visible, onCancel } = this.props;

        return (
            <Modal
                maskClosable={false}
                style={{ top: 15 }}
                width={800}
                title="ลบสถานที่/ร้านค้า/ที่พัก"
                visible={visible}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        ยกเลิก
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.onDeletePlace} loading={this.props.loading}>
                        บันทึก
                        </Button>,
                ]}
            >
                <h3>ต้องการลบข่าว <Typography.Text type="warning">{this.props.place.place_title}</Typography.Text> ใช่หรือไม่?</h3>
            </Modal>
        );
    }
}

export { DeletePlaceForm };
