import React, { Component } from 'react'
import { Modal, Button, notification } from 'antd'
import 'antd/dist/antd.css';
import '../gallery.css';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class DeleteGalleryForm extends Component {

    onDeleteGallery = async (e) => {
        e.preventDefault();

        const gallery = this.props.gallery;
        const response = await this.props.onDeleteGallery(gallery);

        if (response.success) {
            this.props.onCancel();
        } else {
            notify(response.success, response.message);
        }
    }

    render() {
        const { visible, onCancel } = this.props;

        return (
            <Modal
                maskClosable={false}
                style={{ top: 15 }}
                width={800}
                title="ลบภาพ"
                visible={visible}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        ยกเลิก
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.onDeleteGallery} loading={this.props.loading}>
                        บันทึก
                        </Button>,
                ]}
            >
                <h3>ต้องการลบภาพใช่หรือไม่?</h3>
            </Modal>
        );
    }
}

export { DeleteGalleryForm };
