import React, { Component } from "react"
import { Form, Icon, Input, Button, Row, Card, notification, Spin } from 'antd'
import './signin.css'
import axios from '../../service/service'
import IconUSBT from '../../img/logo_icon.png';

const notify = (description) => {

  notification['error']({
    message: 'ข้อความจากระบบ',
    description: description,
    duration: 2,
  });
};

const spin = <Icon type="loading" style={{ fontSize: 36 }} spin />;


class Signin extends Component {

  constructor(pros) {
    super(pros);

    if (localStorage.getItem('token') != null) {
      this.props.history.push('/');
    }

    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    document.title = 'เข้าสู่ระบบ';
  }

  handleSubmit = (e) => {

    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        this.setState({
          loading: true
        });

        try {
          const response = await axios.post('/member/login', values);
          localStorage.setItem('token', response.data.token);
          this.props.history.push('/');

        } catch (error) {

          notify(error.message);
          this.props.form.resetFields();

          this.setState({
            loading: false
          });

        }
      }
    });
  }

  isLoading = (_bool) => {
    this.setState({ loading: _bool })
  }

  pageUnLoading = () => {
    this.setState({ loading: false })
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <Spin indicator={spin} spinning={this.state.loading}>
        <Row justify="center" className="bg">
          <div className="card">
            <Form onSubmit={this.handleSubmit}>
              <Card>
                <div className="header-login">
                  <h4>เข้าสู่ระบบ @USBT</h4>
                  <div className="icon-line">
                    <a href="https://usbtthailand.net/" target="_blank">
                      <img src={IconUSBT} />

                    </a>
                  </div>
                </div>
                

                <Form.Item>
                  {
                    getFieldDecorator('member_email', {
                      rules: [
                        {
                          required: true,
                          message: 'กรุณาใส่อีเมลล์'
                        }
                      ]
                    })(<Input maxLength={32} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="อีเมลล์" />)
                  }
                </Form.Item>
                <Form.Item>
                  {
                    getFieldDecorator('member_pass', {
                      rules: [
                        {
                          required: true,
                          message: 'กรุณาใส่รหัสผ่าน'
                        }
                      ]
                    })(<Input maxLength={32} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="รหัสผ่าน" />)
                  }
                </Form.Item>
                <div className="footer">
                  <Button block={true} className="btn" htmlType={'submit'}>
                    เข้าสู่ระบบ
                </Button>
                </div>
              </Card>
            </Form>
          </div>
        </Row>
      </Spin>
    )
  }

}

const WrappedNormalLoginForm = Form.create({ name: 'login' })(Signin);

export default (WrappedNormalLoginForm)
