import React, { Component } from 'react'
import { Modal, Form, Input, Button, notification, DatePicker } from 'antd'
import 'antd/dist/antd.css';
import '../blog.css';
import FileUpload from '../../../components/FileUpload';
import CKEditor from "@ckeditor/ckeditor5-react";// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import editorConfiguration from '../../../variables/editorConfiguration';
import domain from '../../../service/config'
import moment from 'moment';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class EditBlogForm extends Component {

    constructor(props) {
        super(props);

        const { blog } = props;

        this.state = {
            show_image: {
                file: domain + blog.blog_image,
                name: ""
            },
            blog_image: null,
            blog_description: blog.blog_description,
            blog_title: blog.blog_title,
            blog_author: blog.blog_author,
            blog_date: blog.blog_date,
            loading: false,
        }
    }

    inputImageOnChange = (e) => {

        const file = e.target.files[0]
        if (file != null) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let image = this.state.image
                image = { file: reader.result, name: file.name };

                // this.props.onSetImageFile(image);
                this.setState({ blog_image: file, show_image: image })
            }
            reader.onerror = error => {
                console.error(error)
            }
        } else {
            console.log("no file");
            
        }

    }

    onEditBlog = async (e) => {
        e.preventDefault();
        
        let blog = {
            _id: this.props.blog._id,
            blog_title: "",
            blog_image: "",
            blog_description: "",
            blog_author: "",
            blog_date: ""
        };

        const { blog_title, blog_author, blog_image, blog_description, blog_date } = this.state;
        const old_blog = this.props.blog;


        blog.blog_title = blog_title !== "" ? blog_title : old_blog.blog_title;
        blog.blog_image = (blog_image !== ""  && blog_image != null) ? blog_image : "";
        blog.blog_author = blog_author !== "" ? blog_author : old_blog.blog_title;
        blog.blog_description = blog_description !== "" ? blog_description : old_blog.blog_description;
        blog.blog_date = blog_date !== "" ? moment(blog_date) : moment(old_blog.blog_date);

        const response = await this.props.onEditBlog(blog);

        if (response.success) {
            this.onCancelEditBlog();
        } else {
            notify(response.success, response.message);
        }
    }

    onCancelEditBlog = () => {
        this.setState({
            blog_image: null,
            show_image: null,
            blog_description: "",
            blog_title: "",
            blog_author: "",
            blog_date: ""
        });

        this.props.onCancel();
    }

    render() {
        const { visible } = this.props;

        const { blog_title, blog_author, blog_description, show_image, blog_date } = this.state;

        let disabled = true;
        if (blog_title !== "" && blog_author !== "" && blog_description !== "" && blog_date !== "") {
            disabled = false;
        }

        return (
            <Modal
                maskClosable={false}
                style={{ top: 15 }}
                width={800}
                title="แก้ไขบล็อก"
                visible={visible}
                onCancel={this.onCancelEditBlog}
                footer={[
                    <Button key="back" onClick={this.onCancelEditBlog}>
                        ยกเลิก
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.onEditBlog} loading={this.props.loading} disabled={disabled}>
                        บันทึก
                        </Button>,
                ]}
            >
                <Form layout="vertical">
                    <Form.Item label="อัพโหลดรูปภาพ (ขนาดภาพที่แนะนำคือ 800 X 400)">
                        <FileUpload id="blog_image" inputImageOnChange={this.inputImageOnChange} image={show_image} />
                    </Form.Item>
                    <Form.Item label="หัวเรื่อง">
                        <Input placeholder="กรุณาใส่หัวเรื่อง" value={blog_title} onChange={(e) => { this.setState({ blog_title: e.target.value }) }} />
                    </Form.Item>
                    <Form.Item label="วันที่เขียน">
                        <DatePicker style={{ width: "100%" }} defaultValue={moment(blog_date)} onChange={(date, dateString) => { this.setState({ blog_date: dateString }) }} placeholder="กรุณาเลือกวันที่" />
                    </Form.Item>
                    <Form.Item label="บทความ">
                        <CKEditor
                            style={{ borderColor: "red" }}
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={blog_description}
                            onChange={(event, editor) => {
                                const data = editor.getData();

                                // this.props.onSetDescription(data);
                                this.setState({ blog_description: data })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="ผู้เขียน">
                        <Input placeholder="กรุณาใส่ชื่อผู้เขียน" value={blog_author} onChange={(e) => { this.setState({ blog_author: e.target.value }) }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export { EditBlogForm };
