import React, { Component } from 'react'
import { Modal, Form, Button, notification } from 'antd'
import 'antd/dist/antd.css';
import '../gallery.css';
import FileUpload from '../../../components/FileUpload';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class AddGalleryForm extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            show_image: null,
            gallery_image: null,
            loading: false
        }
    }

    inputImageOnChange = (e) => {

        const file = e.target.files[0]
        if (file != null) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let image = this.state.image
                image = { file: reader.result, name: file.name };

                // this.props.onSetImageFile(image);
                this.setState({ gallery_image: file, show_image: image })
            }
            reader.onerror = error => {
                console.error(error)
            }
        }

    }

    onCreateGallery = async (e) => {
        e.preventDefault();
        const { gallery_image } = this.state;
        const gallery = { gallery_image };
        const response = await this.props.onCreateGallery(gallery);

        if (response.success) {
            this.onCancelGallery();
        } else {
            notify(response.success, response.message);
        }
    }

    onCancelGallery = () => {
        this.setState({
            gallery_image: null,
            show_image: null,
        });

        this.props.onCancel();
    }

    render() {
        const { visible } = this.props;

        const { gallery_image } = this.state;

        let disabled = false;
        if (gallery_image == null) {
            disabled = true;
        }

        return (
            <Modal
                maskClosable={false}
                style={{ top: 15 }}
                width={800}
                title="อัพโหลด"
                visible={visible}
                onCancel={this.onCancelGallery}
                footer={[
                    <Button key="back" onClick={this.onCancelGallery}>
                        ยกเลิก
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.onCreateGallery} loading={this.props.loading} disabled={disabled}>
                        บันทึก
                        </Button>,
                ]}
            >
                <Form layout="vertical">
                    <Form.Item label="อัพโหลดรูปภาพ">
                        <FileUpload id="gallery_image" inputImageOnChange={this.inputImageOnChange} image={this.state.show_image} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export { AddGalleryForm };
