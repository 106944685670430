import React, { Component } from 'react'
import { Modal, Form, Input, Button, notification, Select, DatePicker } from 'antd'
import 'antd/dist/antd.css';
import '../place.css';
import FileUpload from '../../../components/FileUpload';
import CKEditor from "@ckeditor/ckeditor5-react";// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import editorConfiguration from '../../../variables/editorConfiguration';
import provinces from './province';
import moment from 'moment';

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};

class AddPlaceForm extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            show_image: null,
            place_image: null,
            place_description: "",
            place_title: "",
            place_author: "",
            place_type: "ร้านค้า",
            place_province: "กรุงเทพมหานคร",
            place_date: "",
            loading: false
        }
    }

    inputImageOnChange = (e) => {

        const file = e.target.files[0]
        if (file != null) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let image = this.state.image
                image = { file: reader.result, name: file.name };

                // this.props.onSetImageFile(image);
                this.setState({ place_image: file, show_image: image })
            }
            reader.onerror = error => {
                console.error(error)
            }
        }

    }

    onCreatePlace = async (e) => {
        e.preventDefault();
        const { place_title, place_author, place_image, place_description, place_type, place_province, place_date } = this.state;
        const place = { place_title, place_author, place_image, place_description, place_type, place_province, place_date: moment(place_date) };
        const response = await this.props.onCreatePlace(place);

        if (response.success) {
            this.onCancelPlace();
        } else {
            notify(response.success, response.message);
        }
    }

    onCancelPlace = () => {
        this.setState({
            place_image: null,
            show_image: null,
            place_description: "",
            place_title: "",
            place_author: "",
            place_type: "",
            place_province: "",
        });

        this.props.onCancel();
    }

    render() {
        const { visible } = this.props;

        const { place_title, place_author, place_image, place_description, place_type, place_province, place_date } = this.state;

        let disabled = false;
        if (place_title === "" || place_author === "" || place_image == null || place_description === "" || place_type === "" || place_province === "" || place_date === "") {
            disabled = true;
        }

        return (
            <Modal
                maskClosable={false}
                style={{ top: 15 }}
                width={800}
                title="เพิ่มสถานที่/ร้านค้า/ที่พัก"
                visible={visible}
                onCancel={this.onCancelPlace}
                footer={[
                    <Button key="back" onClick={this.onCancelPlace}>
                        ยกเลิก
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.onCreatePlace} loading={this.props.loading} disabled={disabled}>
                        บันทึก
                        </Button>,
                ]}
            >
                <Form layout="vertical">
                    <Form.Item label="ประเภทสถานที่">
                        <Select defaultValue={this.state.place_type} onChange={(e) => { this.setState({ place_type: e }) }}>
                            <Select.Option value="ร้านค้า">ร้านค้า</Select.Option>
                            <Select.Option value="ที่พัก">ที่พัก</Select.Option>
                            <Select.Option value="สถานที่อื่นๆ">สถานที่อื่นๆ</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="จังหวัด">
                        <Select defaultValue={this.state.place_province} onChange={(e) => { this.setState({ place_province: e }) }}>
                            { provinces.map((province) => <Select.Option key={province} value={province}>{province}</Select.Option>) }
                        </Select>
                    </Form.Item>
                    <Form.Item label="อัพโหลดรูปภาพ (ขนาดภาพที่แนะนำคือ 800 X 400)">
                        <FileUpload id="place_image" inputImageOnChange={this.inputImageOnChange} image={this.state.show_image} />
                    </Form.Item>
                    <Form.Item label="หัวเรื่อง">
                        <Input placeholder="กรุณาใส่หัวเรื่อง" value={this.state.place_title} onChange={(e) => { this.setState({ place_title: e.target.value }) }} />
                    </Form.Item>
                    <Form.Item label="วันที่เขียน">
                        <DatePicker style={{ width: "100%" }} onChange={(date, dateString) => { this.setState({ place_date: dateString }) }} placeholder="กรุณาเลือกวันที่" />
                    </Form.Item>
                    <Form.Item label="บทความ">
                        <CKEditor
                            style={{ borderColor: "red" }}
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={this.state.place_description}
                            onChange={(event, editor) => {
                                const data = editor.getData();

                                // this.props.onSetDescription(data);
                                this.setState({ place_description: data })
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="ผู้เขียน">
                        <Input placeholder="กรุณาใส่ชื่อผู้เขียน" value={this.state.place_author} onChange={(e) => { this.setState({ place_author: e.target.value }) }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export { AddPlaceForm };
