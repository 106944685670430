import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom'
import './App.css';
import { Main } from './containers/main'
import { Signin } from './containers/signin'


// import CKEditor from "@ckeditor/ckeditor5-react";// NOTE: Use the editor from source (not a build)!
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import editorConfiguration from './variables/editorConfiguration';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/Signin" component={Signin} />
        </Switch>
      </HashRouter>
    )
  }
}

export default App;
