import React, { Component } from 'react';
import { Button, Divider, Col, Row, Card, Icon, Empty, Pagination, Typography, Input, Spin } from 'antd'
import './support.css'
import axios from '../../service/service'
import moment from 'moment';
import 'moment/locale/th';
import domain from '../../service/config'
import { AddSupportForm } from './form/AddSupportForm';
import { DeleteSupportForm } from './form/DeleteSupportForm';

class Support extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        moment().locale('th');

        this.state = {
            supports: null,
            total: null,
            per_page: null,
            total_pages: null,
            current_page: 1,
            row: 4,
            loadingAdd: false,
            loadingDelete: false,
            visibleAddForm: false,
            visibleDeleteForm: false,
            currentDeleteSupport: null,
            loading: false
        };
    }

    componentDidMount() {
        document.title = 'ผู้สนับสนุน';
        this.getSupports(1);
    }

    getSupports = async (pageNumber) => {
        this._isMounted = true;
        try {
            const response = await axios.get('/support?page=' + pageNumber);

            if (this._isMounted) {
                this.setState({
                    supports: response.data.support.length === 0 ? null : response.data.support,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    supports: error.message
                });
            }
        }
    }

    showAddModal = () => {
        this.setState({ visibleAddForm: true });
    };

    handleCancelAddForm = () => {
        this.setState({ visibleAddForm: false });
    };

    handleAddForm = async (support) => {

        let data = new FormData();

        for (const [key, value] of Object.entries(support)) {
            data.append(key, value);
        }

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.post('/support', data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getSupports(1);

        this.setState({
            loadingAdd: false
        });

        return res;
    };

    showDeleteModal = (support) => {
        this.setState({ visibleDeleteForm: true, currentDeleteSupport: support });
    };

    handleCancelDeleteForm = () => {
        this.setState({ visibleDeleteForm: false, currentDeleteSupport: null });
    };

    handleDeleteForm = async (support) => {
        const _id = support._id;

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.delete('/support/' + _id, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getSupports(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    }

    renderSupports = () => {
        if (this.state.supports != null) {
            let rows = [];

            for (let i = 0; i < Math.ceil(this.state.supports.length / this.state.row); i++) {
                rows.push(i);
            }

            let start = 0
            let end = this.state.row;
            let support = rows.map(i => {
                let support = this.state.supports.slice(i + start, i + end);

                start = end - 1;
                end = start + this.state.row;

                const new_ = support.map((key, index) => {
                    const { Meta } = Card;

                    return (<Col key={key._id} md={6} xm={6} style={{ paddingBottom: 8 }}>
                        <Card
                            style={{ width: '100%' }}
                            cover={<img alt={key._id} src={domain + key.support_image} height="200" />}
                            actions={[
                                <a value={key._id} onClick={() => this.showDeleteModal(key)}><Icon type="close" /></a>
                            ]}
                        >
                            <Meta title={key.place_title} description={'วันที่ ' + moment(key.createdAt).format('DD/MM/YYYY')} />
                        </Card>
                    </Col>);
                })

                let row = <Row gutter={6} justify="space-around" align="middle" key={i}>{new_}</Row>

                return row;
            });

            return support;
        }

        return <Empty description={'ไม่มีผู้สนับสนุน'} imageStyle={{
            height: 120,
        }}>
            <Button type="primary" icon="plus" onClick={this.showAddModal}>เพิ่ม</Button>
        </Empty>;
    }

    renderPagination = () => {
        return this.state.supports != null ?
            <Row justify="center" align="middle" type="flex">
                <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
            </Row> : null;
    }

    onChangePage = page => {
        this.getSupports(page);
        this.setState({
            current_page: page,
        });
    };

    render() {
        const supports = this.renderSupports();
        const pagination = this.renderPagination();

        return (
            <div>
                <Typography.Title className="title-default title-top" level={3}>
                    ผู้สนับสนุน
                </Typography.Title>
                <Divider />
                {this.state.supports != null ?
                    <div style={{ marginBottom: 8 }}>
                        <Button
                            type="primary"
                            icon="plus"
                            className="block"
                            onClick={this.showAddModal}
                        >
                            เพิ่ม
                        </Button>
                        <div style={{ float: 'right' }} className="pagination-top">
                            <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
                        </div>
                    </div> : null}

                <Spin tip="Loading..." spinning={this.state.loading}>
                    {supports}
                </Spin>

                {pagination}

                <AddSupportForm
                    visible={this.state.visibleAddForm}
                    onCancel={this.handleCancelAddForm}
                    onCreateSupport={this.handleAddForm}
                    loading={this.state.loadingAdd}
                />

                {this.state.currentDeleteSupport != null ?
                    <DeleteSupportForm
                        visible={this.state.visibleDeleteForm}
                        onCancel={this.handleCancelDeleteForm}
                        onDeleteSupport={this.handleDeleteForm}
                        loading={this.state.loadingDelete}
                        support={this.state.currentDeleteSupport}
                    /> : null}

            </div>
        )
    }
}

export default Support;