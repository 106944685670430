import React, { Component } from 'react'
import { Modal, Form, } from 'antd'

const DeleteMemberForm = Form.create({ name: 'delete_member' })(

    class extends Component {

        render() {
            const { visible, onCancel, onCreate, data, form } = this.props;
            return (

                <Modal
                    title="ลบผู้ใช้"
                    okText={'บันทึก'}
                    cancelText={'ยกเลิก'}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    <p>คุณต้องการลบผู้ใช้ {data.member_email} ใช่หรือไม่?</p>
                </Modal>
            );
        }
    },
);

export { DeleteMemberForm };
