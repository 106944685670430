import React, { Component } from 'react';
import { Layout, Menu, Icon, Button, Dropdown, Card, Avatar, Spin } from 'antd';
import { Link, Switch, Route, BrowserRouter } from 'react-router-dom'
import routes from '../../routes/routes';
import './main.css'
import axios from '../../service/service'
import { EditProfileForm } from './form/editProfileForm';

const { Header, Sider, Content } = Layout;

const { Meta } = Card;

const spin = <Icon type="loading" style={{ fontSize: 36 }} spin />;

class Main extends Component {
  _isMounted = false;

  constructor(props) {
    super(props)

    if (!localStorage.getItem('token')) {
      props.history.push('/signin');
    }

    this.state = {
      collapsed: false,
      loading: false,
      loadingProfile: false,
      profile: null,
      role: '1',
      visibleEditProfileForm: false,
    };

  }

  componentDidMount() {
    this.getProfile();
  }

  getProfile = async () => {
    this._isMounted = true;
    try {
      const response = await axios.post('/member/me', null, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });

      if (this._isMounted) {
        this.setState({
          profile: response.data.member,
          role: response.data.member.member_role
        });
      }

    } catch (error) {
      if (this._isMounted) {
        this.setState({
          profile: error.message
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  logout = async () => {

    this.setState({
      loading: true
    })

    await axios.post('/member/logout', null, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    });

    localStorage.removeItem('token');

    this.setState({
      loading: false
    })

    this.props.history.push('/signin');

  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showEditProfileModal = () => {
    this.setState({ visibleEditProfileForm: true });
  }

  handleCancelEditProfileForm = () => {
    const { form } = this.editProfileFormRef.props;
    form.resetFields();

    this.setState({ visibleEditProfileForm: false });
  };

  handleEditProfileForm = () => {
    const { form } = this.editProfileFormRef.props;

    form.validateFields(async (err, values) => {

      this.setState({
        loadingProfile: true
      });

      if (!err) {

        if (values.member_pass === "") {
          delete values.member_pass;
        }

        try {
          const response = await axios.patch('/member/updateprofile', values, {
            headers: {
              Authorization: localStorage.getItem('token')
            }
          });

          this.setState({
            profile: response.data,
            visibleEditProfileForm: false,
            loadingProfile: false
          });

        } catch (error) {

          this.setState({
            profile: error.response.data.error,
            visibleEditProfileForm: false,
            loadingProfile: false
          });
        }
      }

    });

  };

  saveEditProfileFormRef = formRef => {
    this.editProfileFormRef = formRef;
  };

  renderMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Card
            style={{ width: 300 }}
            actions={[
              <Icon type="edit" key="edit" onClick={this.showEditProfileModal} />,
              <Icon type="logout" key="logout" onClick={this.logout} />,
            ]}
          >
            <Meta
              avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
              title={this.state.profile != null ? this.state.profile.member_firstname + " " + this.state.profile.member_lastname : ""}
              description={this.state.profile != null ? this.state.profile.member_email : ""}
            />
          </Card>
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    return (
      <BrowserRouter>
        <Spin indicator={spin} spinning={this.state.loading}>
          <div>

            {this.state.profile != null ?

              <EditProfileForm
                wrappedComponentRef={this.saveEditProfileFormRef}
                visible={this.state.visibleEditProfileForm}
                data={this.state.profile}
                loading={this.state.loadingProfile}
                onCancel={this.handleCancelEditProfileForm}
                onCreate={this.handleEditProfileForm}

              /> : null}

            <Layout className="layout">
              <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                <div className="sider">
                  {"DSPORT"}
                </div>
                {this.state.profile != null ?
                  <Menu theme="dark" mode="inline" defaultSelectedKeys={[this.state.role]}>
                    {this.state.role == "1" ? 
                    <Menu.Item key="1">
                      <Link to="/" className="nav-text">
                        <Icon type="dashboard" />
                        <span>จัดการผู้ใช้</span>
                      </Link>
                    </Menu.Item> : null }
                    <Menu.Item key="2">
                      <Link to="/blog" className="nav-text">
                        <Icon type="profile" />
                        <span>บล็อก</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                      <Link to="/news" className="nav-text">
                        <Icon type="read" />
                        <span>ข่าวสาร</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                      <Link to="/place" className="nav-text">
                        <Icon type="shopping" />
                        <span>สนามแข่ง/ร้านค้า/ที่พัก</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5">
                      <Link to="/support" className="nav-text">
                        <Icon type="team" />
                        <span>ผู้สนับสนุน</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <Link to="/gallery" className="nav-text">
                        <Icon type="picture" />
                        <span>ภาพรวม</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7">
                      <Link to="/setting" className="nav-text">
                        <Icon type="setting" />
                        <span>ตั้งค่า</span>
                      </Link>
                    </Menu.Item>
                  </Menu> : null}
              </Sider>
              <Layout>
                <Header className="header">
                  <Icon
                    className="trigger"
                    style={{ paddingLeft: 22 }}
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.toggle}
                  />
                  <Dropdown overlay={this.renderMenu} placement="bottomLeft">
                    <Button type="defualt" style={{ float: 'right', marginRight: 10, marginTop: 15 }}>โปรไฟล์</Button>
                  </Dropdown>

                </Header>
                <Content className="content">
                  <Switch>
                    {
                      this.state.profile != null ?
                        routes.map((route, idx) => {
                          if (this.state.role == "2") {
                            if (route.name != "dashboard") {
                              return route.component
                                ? (<Route key={idx} path={route.path} exact={route.exact}
                                  name={route.name} render={props => (<route.component {...props} />)} />)
                                : (null);
                            }
                          } else {
                            return route.component
                              ? (<Route key={idx} path={route.path} exact={route.exact}
                                name={route.name} render={props => (<route.component {...props} />)} />)
                              : (null);
                          }
                        }) : null
                    }
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </div>
        </Spin>
      </BrowserRouter>
    );
  }
}

export default Main;