import React, { Component } from 'react';
import { Button, Divider, Typography, Input, Form, Modal, notification } from 'antd'
import './setting.css'
import axios from '../../service/service'
import moment from 'moment';
import 'moment/locale/th';
import domain from '../../service/config'

const notify = (success, description) => {

    notification[success ? 'success' : 'error']({
        message: 'ข้อความจากระบบ',
        description: description,
        duration: 2,
    });
};


class Setting extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        moment().locale('th');

        this.state = {
            loading: false,
            config: null,
            visible: false,
            current: null,
            type: null,
            title: null
        };
    }

    componentDidMount() {
        document.title = 'ตั้งค่า';
        this.getSetting();
    }

    getSetting = async () => {
        this._isMounted = true;
        try {
            const response = await axios.get('/setting', {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });

            if (this._isMounted) {
                this.setState({
                    config: response.data.length === 0 ? null : response.data,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    config: error.message
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll( async (err, values) => {
            if (!err) {
                try {
                    console.log(values);
                    
                    // let values = {};
                    // values[this.state.type] = this.state.current;
                    const response = await axios.patch('/setting', values, {
                        headers: {
                            'Authorization': localStorage.getItem('token'),
                            'content-type': 'application/json'
                        }
                    });

                    notify(true, "บันทึกข้อมูลเรียบร้อย");

                } catch (error) {
                    notify(false, error.message);
                }

                this.onCancel();
                this.getSetting();
            }
        });



    };

    handleClick = e => {
        const values = (e.target.value).split(",");

        this.setState({
            current: this.state.config[values[0]],
            type: values[0],
            title: values[1],
            visible: true
        })
    }

    onCancel = () => {

        this.setState({
            current: null,
            type: null,
            title: null,
            visible: false
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Typography.Title className="title-default title-top" level={3}>
                    ตั้งค่า
                </Typography.Title>
                <Divider />
                <div className="center">
                    <Button size={'large'} className="btn-margin facebook" value="facebook,Facebook" onClick={this.handleClick}>
                        Facebook
                    </Button>
                    <Button size={'large'} className="btn-margin youtube" value="youtube,Youtube" onClick={this.handleClick}>
                        Youtube
                    </Button>
                    <Button size={'large'} className="btn-margin twitter" value="twitter,Twitter" onClick={this.handleClick}>
                        Twitter
                    </Button>

                    <Button size={'large'} className="btn-margin game-score" value="game_score,Game Score Api" onClick={this.handleClick}>
                        Game Score Api
                    </Button>
                    <Button size={'large'} className="btn-margin game-medel" value="game_medal,Game Medel Api" onClick={this.handleClick}>
                        Game Medel Api
                    </Button>
                    <Button size={'large'} className="btn-margin game-medel-rank" value="game_medel_rank,Game Medel Rank Api" onClick={this.handleClick}>
                        Game Medel Rank Api
                    </Button>
                </div>

                {this.state.current != null ?
                    <Modal
                        maskClosable={false}
                        style={{ top: 15 }}
                        width={800}
                        title={this.state.title}
                        visible={this.state.visible}
                        onCancel={this.onCancel}
                        footer={[
                            <Button key="back" onClick={this.onCancel}>
                                ยกเลิก
                        </Button>,
                            <Button key="submit" type="primary" onClick={this.handleSubmit}>
                                บันทึก
                        </Button>,
                        ]}
                    >

                        <Form layout="vertical">
                            <Form.Item>
                                {getFieldDecorator(this.state.type, {
                                    initialValue: this.state.current,
                                    rules: [
                                        {
                                            pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                            message: 'กรุณากรอก url ให้ถูกต้อง',
                                        },
                                        {
                                            required: true,
                                            message: 'กรุณาใส่ข้อมูล',
                                        },
                                    ],
                                })(<Input placeholder={this.state.type} />)}

                            </Form.Item>
                        </Form>
                    </Modal> : null}
            </div>
        );
    }
}


const SettingForm = Form.create({ name: 'setting' })(Setting);
export default SettingForm;