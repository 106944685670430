import React, { Component } from 'react';
import { Button, Divider, Col, Row, Card, Icon, Empty, Pagination, Typography, Input, Spin } from 'antd'
import './news.css'
import axios from '../../service/service'
import moment from 'moment';
import 'moment/locale/th';

import { AddNewsForm } from './form/AddNewsForm'
import { EditNewsForm } from './form/EditNewsForm';
import { DeleteNewsForm } from './form/DeleteNewsForm';
import domain from '../../service/config'

class News extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        moment().locale('th');

        this.state = {
            news: null,
            total: null,
            per_page: null,
            total_pages: null,
            current_page: 1,
            row: 4,
            loadingAdd: false,
            loadingEdit: false,
            loadingDelete: false,
            visibleAddForm: false,
            visibleEditForm: false,
            visibleDeleteForm: false,
            currentEditNews: null,
            currentDeleteNews: null,
            status: 1,
            loading: false,
        };
    }

    componentDidMount() {
        document.title = 'ข่าวสาร';
        this.getNews(1);
    }

    getNews = async (pageNumber) => {
        this._isMounted = true;
        try {
            const response = await axios.get('/news?page=' + pageNumber);

            if (this._isMounted) {
                this.setState({
                    news: response.data.news.length === 0 ? null : response.data.news,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    news: error.message
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showAddModal = () => {
        this.setState({ visibleAddForm: true });
    };

    handleCancelAddForm = () => {
        this.setState({ visibleAddForm: false });
    };

    handleAddForm = async (news) => {

        let data = new FormData();

        for (const [key, value] of Object.entries(news)) {
            data.append(key, value);
        }

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.post('/news', data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
            // console.log(response);
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getNews(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    };

    showEditModal = (news) => {
        this.setState({ visibleEditForm: true, currentEditNews: news });
    };

    handleCancelEditForm = () => {
        this.setState({ visibleEditForm: false, currentEditNews: null });
    };

    handleEditForm = async (news) => {
        let data = new FormData();

        const _id = news._id;
        delete news._id;

        for (const [key, value] of Object.entries(news)) {
            data.append(key, value);
        }

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.patch('/news/' + _id, data, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getNews(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    }

    showDeleteModal = (news) => {
        this.setState({ visibleDeleteForm: true, currentDeleteNews: news });
    };

    handleCancelDeleteForm = () => {
        this.setState({ visibleDeleteForm: false, currentDeleteNews: null });
    };

    handleDeleteForm = async (news) => {
        const _id = news._id;

        let res = { success: false, message: "" };
        this.setState({
            loadingAdd: true
        })

        try {
            const response = await axios.delete('/news/' + _id, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'multipart/form-data'
                }
            });
            res.success = true;
        } catch (error) {
            res.success = false;
            res.message = error.response.data.error;
        }

        this.getNews(1);

        this.setState({
            loadingAdd: false,
            status: 1
        });

        return res;
    }

    renderNews = () => {
        if (this.state.news != null) {
            let rows = [];

            for (let i = 0; i < Math.ceil(this.state.news.length / this.state.row); i++) {
                rows.push(i);
            }

            let start = 0
            let end = this.state.row;
            let news = rows.map(i => {
                let news = this.state.news.slice(i + start, i + end);

                start = end - 1;
                end = start + this.state.row;

                const new_ = news.map((key, index) => {
                    const { Meta } = Card;

                    return (<Col key={key._id} md={6} xm={6} style={{ paddingBottom: 8 }}>
                        <Card

                            style={{ width: '100%' }}
                            cover={<img alt={key._id} src={domain + key.news_image} height="200" />}
                            actions={[
                                <Icon type="close" key="close" onClick={() => this.showDeleteModal(key)} value={key._id} />,
                                <Icon type="edit" key="edit" onClick={() => this.showEditModal(key)} value={key._id} />
                            ]}
                        >
                            <Meta title={key.news_title} description={'วันที่ ' + moment(key.news_date).format('DD/MM/YYYY')} />
                        </Card>
                    </Col>);
                })

                let row = <Row gutter={6} justify="space-around" align="middle" key={i}>{new_}</Row>

                return row;
            });

            return news;
        }

        return <Empty description={'ไม่มีข่าว'} imageStyle={{
            height: 120,
        }}>
            <Button type="primary" icon="plus" onClick={this.showAddModal}>เพิ่มข่าวสาร</Button>
        </Empty>;
    }

    renderPagination = () => {
        return this.state.news != null ?
            <Row justify="center" align="middle" type="flex">
                <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
            </Row> : null;
    }

    onChangePage = page => {

        if(this.state.status == 1) {
            this.getNews(page);
        } else if(this.state.status == 2) {
            this.getSearch(page);
        }

        this.setState({
            current_page: page,
        });
    };

    getSearch = async (pageNumber) => {
        try {
            const response = await axios.get('/search/news?word=' + this.state.search + "&page=" + pageNumber);

            if (this._isMounted) {
                this.setState({
                    news: response.data.news.length === 0 ? null : response.data.news,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    news: error.response.data.error
                });
            }
        }
    }

    search = async (value) => {
        this.setState({
            loading: true,
            search: value === "" ? "" : value
        });

        try {
            const response = await axios.get('/search/news?word=' + value + "&page=1");

            if (this._isMounted) {
                this.setState({
                    news: response.data.news.length === 0 ? null : response.data.news,
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.page == null ? 1 : response.data.page,
                    total_pages: response.data.total_pages,
                    status: 2
                });
            }

        } catch (error) {
            if (this._isMounted) {
                this.setState({
                    news: error.response.data.error
                });
            }
        }

        this.setState({
            loading: false
        });
    }

    render() {
        const news = this.renderNews();
        const pagination = this.renderPagination();

        return (
            <div>

                <Typography.Title className="title-default title-top" level={3}>
                    ข่าวสาร
                    <Input.Search
                        placeholder="ค้นหาข่าว"
                        enterButton="ค้นหา"
                        style={{ maxWidth: 300, float: 'right' }}
                        onSearch={this.search}
                    />
                </Typography.Title>


                <Divider />
                {this.state.news != null ?
                    <div style={{ marginBottom: 8 }}>
                        <Button
                            type="primary"
                            icon="plus"
                            className="block"
                            onClick={this.showAddModal}
                        >
                            เพิ่ม
                        </Button>
                        <div style={{ float: 'right' }} className="pagination-top">
                            <Pagination pageSize={this.state.per_page} current={this.state.current_page} onChange={this.onChangePage} total={this.state.total} />
                        </div>
                    </div> : null}
                <Spin tip="Loading..." spinning={this.state.loading}>
                    {news}
                </Spin>


                {pagination}
                <AddNewsForm
                    visible={this.state.visibleAddForm}
                    onCancel={this.handleCancelAddForm}
                    onCreateNews={this.handleAddForm}
                    loading={this.state.loadingAdd}
                />

                {this.state.currentEditNews != null ?
                    <EditNewsForm
                        visible={this.state.visibleEditForm}
                        onCancel={this.handleCancelEditForm}
                        onEditNews={this.handleEditForm}
                        loading={this.state.loadingEdit}
                        news={this.state.currentEditNews}
                    /> : null}

                {this.state.currentDeleteNews != null ?
                    <DeleteNewsForm
                        visible={this.state.visibleDeleteForm}
                        onCancel={this.handleCancelDeleteForm}
                        onDeleteNews={this.handleDeleteForm}
                        loading={this.state.loadingDelete}
                        news={this.state.currentDeleteNews}
                    /> : null}

            </div>

        );
    }
}

export default News;